import { Box, Chip, Container, useTheme } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
// import TableList from '../../../components/widgets/tables/TableList';
// import useSettings from '../../../hooks/useSettings';

import toast from 'react-hot-toast';
// import { useTranslation } from 'react-i18next';
import { contactsApi } from 'src/api/contactsApi';
import useAuth from 'src/hooks/useAuth';
// import { Columns } from 'src/types/components/tableList.types';
import setTitle from 'src/utils/setTitle';
// import logger from 'src/utils/logger';
import { Header } from 'src/components/Header';
import { CustomButton2 } from 'src/components/widgets/buttons/CustomButton2';
import useGenericStyles from 'src/styles/useGenericStyles';
import AddIcon from '@material-ui/icons/Add';
import CustomizedTable from 'src/components/tables/CustomizedTable';
import { IsActive } from 'src/components/tables/renderers/IsActive';
import { NameWithAvatar } from 'src/components/tables/renderers/NameWithAvatar';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import { useDialog } from 'src/hooks/useDialog';
import CustomDialog from 'src/components/widgets/Dialog/CustomDialog';
import ButtonTabs from 'src/components/tabs/ButtonTabs';

const ListViewContacts: FC = () => {
  setTitle('ITSM | Contacts');
  const theme = useTheme();
  // const baseUrl = '/contacts';
  // const { t } = useTranslation(['contacts', 'tables']);
  // const { settings } = useSettings();
  const [contacts, setContacts] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  // const [isActivated, setIsActivated] = useState<string>('all');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [fetchingData, setFetchingData] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState<any>('');
  const [selectedTab, setSelectedTab] = useState('all');
  const [rowData, setRowData] = useState({});
  const addContactDialog = useDialog();

  const [sort, setSort] = useState<any>({});

  const handleTabChange = (newValue: string | null) => {
    setSelectedTab(newValue);
  };

  const handleSort = (field) => {
    setSort((prevSort) =>
      prevSort.field === field && prevSort.order === 'desc'
        ? {}
        : {
          field,
          order:
            prevSort.field === field && prevSort.order === 'asc'
              ? 'desc'
              : 'asc',
        }
    );
  };

  const handleApplyFilter = (currentFilterColumn, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [currentFilterColumn]: filterValue,
    }));
    // setModalOpen(false);
  };

  const handleRemoveFilter = (column) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[column];
      return newFilters;
    });
  };

  const handleSearch = (val) => {
    setSearch(val);
  };
  // const [search, setSearch] = useState<string>(
  //   JSON.parse(localStorage.getItem('contactPagePg'))?.search || ''
  // );
  // const [column, setColumn] = useState<string>(
  //   JSON.parse(localStorage.getItem('contactPagePg'))?.column || ''
  // );
  // const [sortVal, setSortVal] = useState<string>(
  //   JSON.parse(localStorage.getItem('contactPagePg'))?.sort || ''
  // );
  // const [isDataRendered, setIsDataRendered] = useState<boolean>(false);
  // type SortDataTypes = {
  //   name: 'asc' | 'desc';
  //   email: 'asc' | 'desc';
  //   phoneNo: 'asc' | 'desc';
  //   status: 'asc' | 'desc';
  // };
  // const [sortData, setSortData] = useState<SortDataTypes>({
  //   name: JSON.parse(localStorage.getItem('contactPagePg'))?.name || 'asc',
  //   email: JSON.parse(localStorage.getItem('contactPagePg'))?.email || 'asc',
  //   phoneNo:
  //     JSON.parse(localStorage.getItem('contactPagePg'))?.phoneNo || 'asc',
  //   status: JSON.parse(localStorage.getItem('contactPagePg'))?.status || 'asc',
  // });
  const { user } = useAuth();

  const getContacts = async () => {
    try {
      setFetchingData(true);
      const res = await contactsApi.getAll(
        page,
        limit,
        selectedTab,
        search,
        sort.field,
        sort.order
      );
      if (res?.status === 200) {
        setContacts(res?.data?.data || []);
        setFetchingData(false);
        setCount(res?.data.totalRecords || 0);
      } else if (res?.status === 204) {
        setFetchingData(false);
        setContacts([]);
        setCount(0);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setFetchingData(false);
        setContacts([]);
        setCount(0);
      }
    }
  };

  const tabs = [
    { title: 'All', value: 'all' },
    { title: 'Active', value: 'true' },
    { title: 'Inactive', value: 'false' },
  ];

  const customColumns = [
    {
      field: 'name',
      headerName: 'NAME',
      render: (columnValue, data) => (
        <NameWithAvatar name={columnValue} rowDataObj={data} />
      ),
      sortable: true,
    },
    { field: 'email', headerName: 'EMAIL', sortable: true },
    {
      field: 'contactNumber',
      headerName: 'CONTACT',
    },
    {
      field: 'isActive',
      headerName: 'STATUS',
      render: (columnValue, data) => <IsActive rowDataObj={data} getContacts={getContacts} isActive={columnValue} />,
    },
  ];

  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const handleLimit = (temp: number) => {
    setLimit(temp);
  };

  // const handleBulkDeactivate = async (checkedIds: Array<number>) => {
  //   try {
  //     const response = await contactsApi.bulkDeactivate(checkedIds);
  //     if (response?.status === 200) {
  //       toast.success('updated successfully');
  //       setIsDataRendered(!isDataRendered);
  //     }
  //     if (response?.status === 204) {
  //       toast.error('try again later');
  //     }
  //   } catch (error) {
  //     logger(error);
  //     toast.error('try again later');
  //   }
  // };

  // const handleStatusToggle = async (id: number, _isActivated: boolean) => {
  //   try {
  //     const response: any = await contactsApi.updateStatusById(
  //       id,
  //       _isActivated
  //     );
  //     if (response?.status === 200) {
  //       toast.success('updated successfully');
  //       setIsDataRendered(!isDataRendered);
  //     }
  //   } catch (error) {
  //     logger(error);
  //     toast.error('try again later');
  //   }
  // };

  useEffect(() => {
    if (
      !user?.role?.some(
        (roleObj) => roleObj?.identifier === 'client_contact_admin' || roleObj.identifier === 'client_contact_user'
      )
    ) {
      window.location.href = '/admin/not-found';
      return;
    }
    getContacts();
    // const contactPagePg = {
    //   contactPageLimit: limit,
    //   contactPageNo: page,
    //   employeeSearch: search,
    //   name: sortData?.name,
    //   phoneNo: sortData?.phoneNo,
    //   email: sortData?.email,
    //   status: sortData?.status,
    //   column,
    //   sort: sortVal,
    // };
    // localStorage.setItem('contactPagePg', JSON.stringify(contactPagePg));
  }, [
    page,
    limit,
    // isDataRendered,
    // sortData?.name,
    // sortData?.phoneNo,
    // sortData?.email,
    // sortData?.status,
    search,
    JSON.stringify(sort),
    selectedTab,
    // column,
    // sortVal,
  ]);

  const classes = useGenericStyles();

  const handleOnClick = (rowDataa: any) => {
    addContactDialog.handleOpen()
    setRowData(rowDataa);
  }

  useEffect(() => {
    if (!addContactDialog?.open) {
      setRowData(null);
    }
  }, [addContactDialog?.open])
  return (
    <>
      <Header title="Users">
        <CustomButton2
          backgroundColor={theme.palette.primary.main}
          className={classes.button}
          onClick={addContactDialog.handleOpen}>
          <AddIcon
            sx={{
              marginRight: '5px',
            }}
          />
          New contact
        </CustomButton2>
      </Header>
      <Container className={classes.container} maxWidth={false}>
        <div>
          {Object.keys(filters).map((column) => (
            <Chip
              key={column}
              label={`${column}: ${filters[column]}`}
              onDelete={() => handleRemoveFilter(column)}
            />
          ))}
        </div>
        <Box
          sx={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <SearchBar
            onSearch={handleSearch}
            value={search}
            placeholderData="Search ..."
          // anyState={autoRefresh !== 0}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}>
            <Box
              component="span"
              sx={{
                fontSize: 12,
                fontWeight: 700,
                color: '#7A7A9D',
              }}>
              Sort By:
            </Box>
            <ButtonTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={handleTabChange}
            />
          </Box>
        </Box>
        <CustomizedTable
          heading="Users"
          columns={customColumns}
          data={contacts}
          totalRecords={count}
          handlePage={handlePage}
          handleLimit={handleLimit}
          page={page}
          limit={limit}
          loading={fetchingData}
          handleSort={handleSort}
          sort={sort}
          handleApplyFilter={handleApplyFilter}
          filters={filters}
          handleOnClick={handleOnClick}
        />
        {/* <TableList
            isCheckbox={false}
            pageName="contacts"
            allowedColumns={columns}
            setIsActivated={setIsActivated}
            count={count}
            page={page}
            setSearch={setSearch}
            search={search}
            limit={limit}
            setIsDataRendered={setIsDataRendered}
            handleLimit={handleLimit}
            handlePage={handlePage}
            handleBulkDeactivate={handleBulkDeactivate}
            handleStatusToggle={handleStatusToggle}
            data={contacts}
            redirectUrl={`${baseUrl}/add`}
            editRouteUrl={`${baseUrl}/edit`}
            getAllSortedData={getAllSortedData}
            column={column}
            setColumn={setColumn}
            sortVal={sortVal}
            setSortVal={setSortVal}
            setSortData={setSortData}
            localStorageValue="contactPagePg"
            searchPlaceholder={t('contacts:contacts_search_placeholder')}
          /> */}
      </Container>
      {addContactDialog.open && <CustomDialog
        isOpen={addContactDialog.open}
        handleCloseDialog={addContactDialog}
        rowData={rowData}
      />}
      {/* <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         
        </Box>
      </Modal> */}
    </>
  );
};

export default ListViewContacts;
